"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setApprovedApplication = exports.setPendingDecisionDetails = exports.setCreditApplication = exports.clearPersonalInfo = exports.clearPractice = exports.setPrequalification = exports.setLenderName = exports.setLenderReferenceId = exports.clearPracticeLocations = exports.setPracticeLocations = exports.setPersonalInfo = exports.setPracticesList = exports.setPractice = exports.clearApplicationEnvironment = exports.setProveVerify = exports.setProveApp = exports.clearProveVerify = exports.clearProveApp = exports.setHideStepper = void 0;
const redux_actions_1 = require("redux-actions");
exports.setHideStepper = (0, redux_actions_1.createAction)('HIDE_STEPPER');
exports.clearProveApp = (0, redux_actions_1.createAction)('CLEAR_PROVE_APP');
exports.clearProveVerify = (0, redux_actions_1.createAction)('CLEAR_PROVE_VERIFY');
exports.setProveApp = (0, redux_actions_1.createAction)('SET_PROVE_APP');
exports.setProveVerify = (0, redux_actions_1.createAction)('SET_PROVE_VERIFY');
exports.clearApplicationEnvironment = (0, redux_actions_1.createAction)('CLEAR_APPLICATION_ENVIRONMENT');
exports.setPractice = (0, redux_actions_1.createAction)('CREDIT_APPLICATION/SET_PRACTICE');
exports.setPracticesList = (0, redux_actions_1.createAction)('CREDIT_APPLICATION/SET_PRACTICE_LIST');
exports.setPersonalInfo = (0, redux_actions_1.createAction)('CREDIT_APPLICATION/SET_PERSONAL_INFO');
exports.setPracticeLocations = (0, redux_actions_1.createAction)('PRACTICE/SEARCH');
exports.clearPracticeLocations = (0, redux_actions_1.createAction)('CLEAR_PRACTICE_LOCATIONS');
exports.setLenderReferenceId = (0, redux_actions_1.createAction)('CREDIT_APPLICATION/SET_LENDER_REFERENCE_ID');
exports.setLenderName = (0, redux_actions_1.createAction)('CREDIT_APPLICATION/SET_LENDER_NAME');
exports.setPrequalification = (0, redux_actions_1.createAction)('CREDIT_APPLICATION/SET_PREQUALIFICATION');
exports.clearPractice = (0, redux_actions_1.createAction)('CREDIT_APPLICATION/CLEAR_PRACTICE');
exports.clearPersonalInfo = (0, redux_actions_1.createAction)('CREDIT_APPLICATION/CLEAR_PERSONAL_INFO');
exports.setCreditApplication = (0, redux_actions_1.createAction)('CREDIT_APPLICATION/SET_CREDIT_APPLICATION');
exports.setPendingDecisionDetails = (0, redux_actions_1.createAction)('CREDIT_APPLICATION/SET_PENDING_DECISION_DETAILS');
exports.setApprovedApplication = (0, redux_actions_1.createAction)('CREDIT_APPLICATION/SET_APPROVED_APPLICATION');
